import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxSpacing,
    CircleStep,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    RadioButtons,
    Select,
    Tag,
    TagNew,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";

import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigThemeField} from "../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeMode} from "../../api/model/cmp/config/theme/CmpConfigThemeMode";
import {CmpConfigThemeModeField} from "../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {ConfigHeader, ConfigThemeImages, Preview, Toolbar} from "../../component/snippet";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {CmpConfigThemeToolbarField} from "../../api/model/cmp/config/theme/CmpConfigThemeToolbarField";
import {CmpConfigThemeToolbarStyle} from "../../api/model/cmp/config/theme/CmpConfigThemeToolbarStyle";
import {CMP_CONFIG_THEME_TOOLBAR_POSITIONS} from "../../api/model/cmp/config/theme/CmpConfigThemeToolbarPosition";
import {CMP_CONFIG_THEME_TOOLBAR_SIZES} from "../../api/model/cmp/config/theme/CmpConfigThemeToolbarSize";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";

function ConfigImages() {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigImages} = useTranslation(TranslationPortalFile.CONFIG_IMAGES);
    const {id: configId} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isPremiumLicense, setPremiumLicense] = useState(false);
    const [isEditMode, setEditMode] = useState(true);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const handleClearImage = async (type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.theme[mode][type] = "";
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleSaveImage = (newImage: string, type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, applyBothMode?: boolean) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        if (applyBothMode) {
            newCmpConfig.settings.theme.lightMode[type] = newImage;
            newCmpConfig.settings.theme.darkMode[type] = newImage;
        } else {
            newCmpConfig.settings.theme[mode][type] = newImage;
        }
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangeWhiteLabel = (checked: boolean) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.theme.whiteLabel = checked;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangeToolbar = (name: string, value: string | number | boolean) => {
        if (cmpConfig.settings.theme.toolbar.active && cmpConfig.settings.theme.toolbar[name] === value) {
            return;
        }

        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.theme.toolbar.active = true;
        newCmpConfig.settings.theme.toolbar[name as string] = value;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleDisableToolbar = () => {
        if (!cmpConfig.settings.theme.toolbar.active) {
            return;
        }

        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.theme.toolbar.active = false;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        try {
            if (isEditMode && !hasUnsavedChanges) {
                return;
            }

            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.IMAGES)?.name) {
                cmpConfig.step = CmpConfigStepName.IMAGES;
            }
            await session.restCmpConfig.update(cmpConfig);
            if (isEditMode) {
                setUnsavedChanges(false);
            }
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const isPremium = await session.isPremium();
                setPremiumLicense(isPremium);

                const newCmpConfig = await session.restCmpConfig.get(configId);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [configId]);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                step={CmpConfigStepName.IMAGES}
                editMode={isEditMode}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <MainContent>
                <MainContentPageHeader
                    title={textConfigImages("title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.IMAGES)}/> : undefined}
                    cssClass="configuration-main-title"
                />
                <LayoutRows>
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL} layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <Loadable loading={isLoading}>
                            <div>
                                <Accordion>
                                    <AccordionItem
                                        heading={
                                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                                <Tag label={textConfigImages("section.images")} icon={{name: "image", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>
                                                <TagNew/>
                                            </FlexContent>
                                        }
                                        startOpen
                                    >
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.LOGO}`)}>
                                                    <ConfigThemeImages
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.LOGO}
                                                        onSave={handleSaveImage}
                                                        onClear={handleClearImage}
                                                        maxFileSize={CmpConfigThemeMode.LOGO_MAX_FILE_SIZE}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.SKIN}`)}>
                                                    <ConfigThemeImages
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.SKIN}
                                                        onSave={handleSaveImage}
                                                        onClear={handleClearImage}
                                                        maxFileSize={CmpConfigThemeMode.SKIN_MAX_FILE_SIZE}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.WATERMARK}`)}>
                                                    <ConfigThemeImages
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.WATERMARK}
                                                        onSave={handleSaveImage}
                                                        onClear={handleClearImage}
                                                    />
                                                </FieldBlock>
                                                {isPremiumLicense &&
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}`)}>
                                                        <ToggleSwitch
                                                            name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}`}
                                                            label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}_label`)}
                                                            checked={!!cmpConfig.settings.theme.whiteLabel}
                                                            onChange={handleChangeWhiteLabel}
                                                        />
                                                    </FieldBlock>
                                                }
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem
                                        heading={
                                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                                <Tag label={textConfigImages("section.toolbar")} icon={{name: "picture_in_picture_alt"}} style={TagStyle.DEFAULT_OCEAN}/>
                                                <TagNew/>
                                            </FlexContent>
                                        }
                                        startOpen
                                    >
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.STYLE}`)}>
                                                        <Select
                                                            value={cmpConfig.settings.theme.toolbar.active ? cmpConfig.settings.theme.toolbar.style : ""}
                                                            options={[
                                                                ({value: "", label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.no_toolbar`)}),
                                                                ...CmpConfigThemeToolbarStyle.values().map((it) =>
                                                                    ({
                                                                        value: it.name,
                                                                        label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.STYLE}_${it.name}`)
                                                                    })
                                                                )]}
                                                            onChange={(option) => option && (option.value ? handleChangeToolbar(CmpConfigThemeToolbarField.STYLE, option.value as string) : handleDisableToolbar())}
                                                        />
                                                    </FieldBlock>
                                                    {cmpConfig.settings.theme.toolbar.active &&
                                                    <Box cssClass={"toolbar-preview-box"} spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                                                        <Toolbar theme={cmpConfig.settings.theme}/>
                                                    </Box>
                                                    }
                                                </FormLayoutRows>
                                                {cmpConfig.settings.theme.toolbar.active &&
                                                    <FormLayoutColumns>
                                                        <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.SIZE}`)}>
                                                            <RadioButtons
                                                                id={`${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.SIZE}`}
                                                                value={cmpConfig.settings.theme.toolbar.size}
                                                                options={CMP_CONFIG_THEME_TOOLBAR_SIZES.map((it) =>
                                                                    ({value: it, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.SIZE}_${it}`)})
                                                                )}
                                                                onChange={(value) => handleChangeToolbar(CmpConfigThemeToolbarField.SIZE, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.POSITION}`)}>
                                                            <RadioButtons
                                                                id={`${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.POSITION}`}
                                                                value={cmpConfig.settings.theme.toolbar.position}
                                                                options={CMP_CONFIG_THEME_TOOLBAR_POSITIONS.map((it) =>
                                                                    ({value: it, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.POSITION}_${it}`)})
                                                                )}
                                                                onChange={(value) => handleChangeToolbar(CmpConfigThemeToolbarField.POSITION, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutColumns>
                                                }
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <div>
                                <div className="configuration-sticky-block">
                                    <Preview cmpConfig={cmpConfig} withActions/>
                                </div>
                            </div>
                        </Loadable>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ConfigImages;
